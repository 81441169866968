import { useState, useEffect } from 'react';
import './ContactForm.css';

const emptyFormData = {
    name: '',
    street: '',
    zipcode: '',
    email: '',
    phone: '',
    weitergabe: false,
    agb: false
};

const ContactForm = ({ location, action, onClose, filterCity, filterServices }) => {
    const [formData, setFormData] = useState({ ...emptyFormData });
    const [isSubmitEnabled, setSubmitEnabled] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    useEffect(() => {
        if (location) {
            setFormData({...formData, id: location.id});
        }
    }, [location]);

    useEffect(() => {
        if (filterCity) {
            setFormData({...formData, filter_city: filterCity});
        }
    }, [filterCity]);

    useEffect(() => {
        if (filterServices) {
            setFormData({...formData, filter_services: filterServices});
        }
    }, [filterServices]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        const newFormData = { ...formData, [name]: newValue };
        setFormData(newFormData);
        checkSubmitEnabled(newFormData);
    };

    const checkSubmitEnabled = (data) => {
        // Check if all required fields and checkboxes are filled/checked
        const isFormValid = data.name && data.email && data.weitergabe && data.agb;
        setSubmitEnabled(isFormValid);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setSubmitting(true); // Disable form while submitting

        try {

            // Get username and password from environment variables
            const username = process.env.REACT_APP_CONTACT_FORM_AUTH_USERNAME;
            const password = process.env.REACT_APP_CONTACT_FORM_AUTH_PASSWORD;

            // Create Basic Auth header
            const authHeader = 'Basic ' + btoa(`${username}:${password}`);

            // Simulate AJAX call to fake endpoint
            const response = await fetch(action, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authHeader,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Show success message
                setSuccess(true);

                // Wait for 2 seconds before calling the close handler
                setTimeout(() => {
                    setFormData(emptyFormData);
                    setSuccess(false);
                    onClose();
                }, 2000);
            } else {
                console.error("Error submitting the form");
            }
        } catch (error) {
            console.error("AJAX error:", error);
        } finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 500);
        }
    };

    const handleClose = () => {
        // reset data on close
        setFormData({...emptyFormData});
        checkSubmitEnabled(emptyFormData);
        if (onClose) {
            onClose();
        }
    }

    const imageContactPerson = () => {
        return location.contactPerson.image
            ? location.contactPerson.image
            :  location.contactPerson.gender == 'male'
                ? process.env.REACT_APP_ASSET_PATH + '/images/contact-person-male-fallback.png'
                : process.env.REACT_APP_ASSET_PATH + '/images/contact-person-female-fallback.png';
    }

    const sublineContactPerson = () => {
        return location.contactPerson.gender == 'male'
            ? 'Ihr Ansprechpartner'
            : 'Ihre Ansprechpartnerin';
    }

    const styleContactPanel = {
        backgroundImage: 'url(' + process.env.REACT_APP_ASSET_PATH  + '/images/contact-form-green-gradient.png)',
        backgroundSize: 'cover'
    };

    const styleContactPanelInner = {
        height: '100%'
    }

    return (
        <div className={`contact-form-container d-flex flex-row ${location ? 'visible' : ''}`}>
            <div className={`contact-form-input-panel`}>
                { location ? (
                <form method='POST' name="contact-form" onSubmit={handleFormSubmit} className='px-lg-4 py-lg-3 py-2 px-3'>
                    <div className='row'>
                        <div className='col-lg-12 contact-form-claim'>
                            <div className='d-flex flex-row justify-content-between'>
                                <div><span>Jetzt Beratungstermin</span> mit <span className="contact-form-open">{location.name}</span> <span>vereinbaren</span>!</div>
                                { location && !location.contactPerson ? (
                                <div onClick={handleClose} className='contact-form-close flex-shrink-0'><svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24"><path d="M18 6 6 18M6 6l12 12"></path></svg></div>
                                ) : <></> }
                            </div>
                        </div>
                        <div className='col-12 mt-4 d-flex flex-column'>
                            <label htmlFor="name" className="form-control-label"><span className="">Vor- und Nachname *</span></label>
                            <input className="form-control" type="text" placeholder='' name="name" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-md-8 mt-3 d-flex flex-column'>
                            <label htmlFor="street" className="form-control-label"><span className="">Straße und Hausnummer</span></label>
                            <input className="form-control" type="text" placeholder='' name="street" onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-md-4 mt-3 d-flex flex-column'>
                            <label htmlFor="zipcode" className="form-control-label"><span className="">Postleitzahl</span></label>
                            <input className="form-control" type="text" placeholder='' name="zipcode" onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-6 mt-3 d-flex flex-column'>
                            <label htmlFor="email" className="form-control-label"><span className="">E-Mail-Adresse *</span></label>
                            <input className="form-control" type="email" placeholder='' name="email" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-6 mt-3 d-flex flex-column'>
                            <label htmlFor="phone" className="form-control-label"><span className="">Telefonnummer</span></label>
                            <input className="form-control" type="text" placeholder='' name="phone" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-12 mt-4 d-flex flex-row align-items-center'>
                            <input className="form-checkbox me-2" type="checkbox" name="weitergabe" required
                                checked={formData.weitergabe}
                                onChange={handleInputChange}
                                disabled={isSubmitting}
                            />
                            <label htmlFor="weitergabe" className="form-checkbox-label"><span>Meine Kontaktdaten dürfen an den E3/DC-Fachpartner weitergegeben werden.*</span></label>
                            {formData.weitergabe ? (
                                <svg className="form-checkbox-check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            ) : <></>}
                        </div>
                        <div className='col-12 mt-2 d-flex flex-row align-items-center'>
                            <input className="form-checkbox me-2" type="checkbox" name="agb" required
                                checked={formData.agb}
                                onChange={handleInputChange}
                                disabled={isSubmitting}
                            />
                            <label htmlFor="agb" className="form-checkbox-label"><span>Ich akzeptiere die <a target="_blank" href="/datenschutz/">Datenschutzbedingungen</a>.*</span></label>
                            {formData.agb ? (
                                <svg className="form-checkbox-check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            ) : <></>}
                        </div>
                        <div className='col-12 my-4 d-flex justify-content-center'>
                            <input className="d-none" type="hidden" name="filter_city" value={filterCity} />
                            <input className="d-none" type="hidden" name="filter_services" value={filterServices} />
                            <input className="d-none" type="hidden" name="id" value={location.id} />
                            {!isSuccess ? (
                                <button className='contact-form-btn py-2' disabled={!isSubmitEnabled || isSubmitting}>
                                    {isSubmitting ? (
                                    // Show loading spinner while submitting
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Senden...</span>
                                    </div>
                                    ) : 'Jetzt Termin vereinbaren'}
                                </button>
                            ) : (
                                <div className='contact-form-success'>Vielen Dank! Wir haben Ihre Kontaktanfrage erhalten!</div>
                            )}
                        </div>
                    </div>
                </form>
                ) : (<></>)
            }
            </div>
            { location && location.contactPerson && false ? (
            <div className={`contact-form-contact-panel d-flex flex-column`} style={styleContactPanel}>
                <div className='px-lg-4 pt-lg-3 pb-lg-4 py-2 px-3 flex-fill'>

                    <div className='d-flex flex-column justify-content-start' style={styleContactPanelInner} /*style="height: 100%"*/>
                        <div className='contact-form-claim'>
                            <div className='d-flex justify-content-end'>
                                <div onClick={handleClose} className='contact-form-close flex-shrink-0'><svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24"><path d="M18 6 6 18M6 6l12 12"></path></svg></div>
                            </div>
                        </div>
                        <div className='contact-form-contact-person-image'>
                        <img alt="Kontaktperson" loading="lazy" decoding="async" data-nimg="1" src={imageContactPerson()} />
                        </div>
                        <div className="contact-form-contact-person-subline mt-2">{ sublineContactPerson() }</div>
                        <div className="contact-form-contact-person-name mt-1 mb-2">{ location.contactPerson.name }</div>
                        {location.email &&
                        <div className="contact-form-contact-person-email my-1 d-flex flex-row align-items-center">
                            <svg className="me-2 flex-shrink-0" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="33.5" height="33.5" rx="16.75" fill="#D4FC37"/>
                            <path d="M9 13.0279L9.71606 13.51L15.6787 17.499C16.1824 17.8332 16.8576 17.8357 17.3613 17.499L23.3239 13.51L24.04 13.0279C23.8086 12.4285 23.1933 12 22.4745 12H10.5655C9.84675 12 9.23142 12.4285 9 13.0279Z" fill="black"/>
                            <path d="M17.8172 18.5562C17.4305 18.8266 16.9739 18.9631 16.52 18.9631C16.0661 18.9631 15.6095 18.8266 15.2228 18.5562L9.80571 14.7758L9 14.2114V20.5645C9 21.4518 9.73857 22.1738 10.6463 22.1738H22.3937C23.3014 22.1738 24.04 21.4518 24.04 20.5645V14.2114L23.2343 14.7758L17.8172 18.5562Z" fill="black"/>
                            </svg>
                            <span class="ellipsis">{ location.email }</span>
                        </div>
                        }
                        {location.phone &&
                        <div className="contact-form-contact-person-phone my-1 d-flex flex-row align-items-center">
                            <svg className="me-2 flex-shrink-0" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="33.5" height="33.5" rx="16.75" fill="#D4FC37"/>
                            <path d="M23.1102 23.8662C22.8083 24.327 22.2839 24.5971 21.7278 24.5654C21.1717 24.5336 20.4567 24.4541 19.9641 24.327C17.7714 23.755 15.4993 22.3886 13.5767 20.466C11.6541 18.5434 10.2876 16.2712 9.71561 14.0785C9.5885 13.586 9.52494 12.8869 9.47727 12.3307C9.44549 11.7905 9.69973 11.2662 10.1605 10.9643L12.1149 9.66137C12.6233 9.3277 13.3066 9.50247 13.5767 10.0427L15.1656 13.1093C15.388 13.5383 15.2768 14.0785 14.8955 14.3804L13.4654 15.4927C14.0375 16.5573 14.5777 17.4153 15.6264 18.4481C16.5956 19.4173 17.4377 19.9893 18.5341 20.5772L19.6622 19.1313C19.9641 18.7499 20.5043 18.6228 20.9333 18.8612L24.0158 20.4501C24.5561 20.7202 24.7308 21.4034 24.3972 21.9119L23.1102 23.8662Z" fill="black"/>
                            </svg>
                            <span className="ellipsis">{ location.phone }</span>
                        </div>
                        }
                        {location.phoneAdditional &&
                            <span className="contact-form-contact-person-phone-additional ellipsis">{ location.phoneAdditional }</span>
                        }
                        <div className="contact-form-social-media flex-fill align-content-end">
                            <div className="social-icons d-flex justify-content-center gap-2">
                            {location.socialMedia.instagram && <div><a target="_blank" href={location.socialMedia.instagram}><img src={process.env.REACT_APP_ASSET_PATH + '/icons/instagram.png'} /></a></div>}
                            {location.socialMedia.facebook && <div><a target="_blank" href={location.socialMedia.facebook}><img src={process.env.REACT_APP_ASSET_PATH + '/icons/facebook.png'} /></a></div>}
                            {location.socialMedia.linkedIn && <div><a target="_blank" href={location.socialMedia.linkedIn}><img src={process.env.REACT_APP_ASSET_PATH + '/icons/linkedIn.png'} /></a></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ) : (<></>)}
        </div>
    );
};

export default ContactForm;